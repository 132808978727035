<template>
  <div class="index">
    <div class="top">
      <img @click="toIndex" src="../assets/blue.svg" alt="蓝色互联（北京）技术有限公司" />
    </div>
    <div class="title t1" >
      <p>蓝色互联，是专注于前沿科技研发与应用的创新型企业。</p>
    </div>
    <div class="title">
      <p>
        连接无限可能，创造智慧未来，团队专为军事单位、国家电网、企业用户提供物联网、算法、软件等方面的技术解决方案。
      </p>
    </div>
    <div class="contnet">
      <div class="list">
        <div @click="toPath('1')">
          <img src="../assets/ocr.png" alt="适用于项目申报或审计的高精准发票批量识别系统" />
          <p>适用于项目申报或审计的高精准发票批量识别系统</p>
        </div>
        <div @click="toPath('2')">
          <img src="../assets/ip.svg" alt="提供全球范围内的IP地址归属地查询" />
          <p>提供全球范围内的IP地址归属地查询</p>
        </div>
        <div @click="toPath('3')">
          <img src="../assets/ydz.png" alt="为退役军人提供优待证资源信息查询" />
          <p>为退役军人提供优待证资源信息查询</p>
        </div>
        <div @click="toPath('4')">
          <img src="../assets/dzh.svg" alt="物联网、算法、软件定制化开发" />
          <p>物联网、算法、软件定制化开发</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <span style="margin-right: 30px" @click="open">京ICP备2023007903号 </span
      ><span @click="connect">联系方式</span>
    </div>
  </div>
</template>
  
  <script lang="ts" setup>
import { fa } from "element-plus/es/locale";
import { ref, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import type { ComponentSize, FormInstance, FormRules } from "element-plus";
import { ElMessage, ElMessageBox } from "element-plus";
import { ElNotification } from "element-plus";
import axios from "axios";
const router = useRouter();
// 打开联系方式
const connect = () => {
  router.push('/connect')
};
// 首页
const toIndex=()=>{
    router.push('/')
}
// 打开京ICP
const open = () => {
  window.open("https://beian.miit.gov.cn/#/Integrated/recordQuery");
};

// 点击进入页面
const toPath = (val) => {
  if (val == 1) {
    window.open("http://ocr.lshl.net/");
  } else if (val == 2) {
    window.open("http://ip.lshl.net/");
  } else if (val == 3) {
    window.open("http://www.youdaizheng.com");
  }
};
// 获取本机ipv4
const fetchPublicIpV4 = () => {
  axios
    .get(config.apiUrlV4 + "/web/query/getMyIp")
    .then((response) => {
      if (response.data.code == 200) {
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
// 获取本机ipv6
const fetchPublicIpV6 = () => {
  axios
    .get(config.apiUrlV6 + "/web/query/getMyIp")
    .then((response) => {
      if (response.data.code == 200) {
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
onMounted(() => {
  fetchPublicIpV4(); //获取本机ipv4
  fetchPublicIpV6(); // 获取本机ipv6
});
</script>
  <style lang="less" scoped>
.index {
  padding: 38px 44px 50px 38px;
  .top {
    img {
      width: 140px;
      cursor: pointer;
    }
    margin-bottom: 52px;
  }
  .title {
    margin: 0 auto;
    width: 850px;
      p {
        font-size: 24px;
        font-weight: normal;
        line-height: normal;
        text-align: center;
        letter-spacing: 0em;
        color: #000000;
      }
    }
    .t1{
      margin-bottom: 40px;
    }
  .contnet {
    margin-top: 127px;
    .list {
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        width: 221px;
        height: 319px;
        border-radius: 2px;
        opacity: 1;
        background: rgba(27, 121, 255, 0.03);

        box-sizing: border-box;
        border: 0.5px solid #1b79ff;
        margin-right: 17px;
        padding: 0 16px;
        text-align: center;
        cursor: pointer;
        img {
          height: 100px;
          margin-top: 60px;
        }
        p {
          margin-top: 30px;
          text-align: left;
          font-size: 18px;
          font-weight: 350;
          color: #000000;
        }
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
      }
      div:hover {
        z-index: 2;
        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
        -webkit-transform: translate3d(0, -2px, 0);
        transform: translate3d(0, -2px, 0);
        border: 2px solid #1b79ff;
      }
    }
  }
}
@media (max-width: 1000px) {
  .index {
    padding: 24px 24px 50px 24px;
    .top {
      img {
        width: 140px;
      }
      margin-bottom: 52px;
    }
    .title {

      margin: 0 auto;
      width: 600px;
      p {
        font-size: 20px;
        font-weight: normal;
        line-height: normal;
        text-align: center;
        letter-spacing: 0em;
        color: #000000;
      }
    }
    .t1{
      margin-bottom: 40px;
    }
    .contnet {
      margin-top: 127px;
      .list {
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          width: 222px;
          height: 319px;
          border-radius: 2px;
          opacity: 1;
          background: rgba(27, 121, 255, 0.03);

          box-sizing: border-box;
          border: 0.5px solid #1b79ff;
          margin-right: 17px;
          padding: 0 20px;
          text-align: center;
          cursor: pointer;
          img {
            height: 80px;
            margin-top: 60px;
          }
          p {
            margin-top: 30px;
            text-align: left;
            font-size: 18px;
            font-weight: 350;
            color: #000000;
          }
          -webkit-transition: all 0.2s linear;
          transition: all 0.2s linear;
        }
        div:hover {
          z-index: 2;
          -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
          -webkit-transform: translate3d(0, -2px, 0);
          transform: translate3d(0, -2px, 0);
          border: 2px solid #1b79ff;
        }
      }
    }
  }
}
@media (max-width: 540px) {
  .index {
    padding: 16px 16px 50px 16px;
    .top {
      img {
        width: 120px;
      }
      margin-bottom: 30px;
    }
    .title {
      // margin: 0 auto;
      text-align: center;
      width: 100%;
      p {
        font-size: 18px;
        font-weight: normal;
        line-height: normal;
        text-align: center;
        letter-spacing: 0em;
        color: #000000;
      }
    }
    .t1{
      margin-bottom: 30px;
    }
    .contnet {
      margin-top: 30px;
      .list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        div {
          width: 48%;
          height: 210px;
          border-radius: 2px;
          opacity: 1;
          background: rgba(27, 121, 255, 0.03);
          box-sizing: border-box;
          border: 0.5px solid #1b79ff;
          padding: 0 10px;
          text-align: center;
          margin-bottom: 17px;
          margin-right: 0;
          cursor: pointer;
          img {
            height: 60px;
            margin-top: 40px;
          }
          p {
            margin-top: 20px;
            text-align: left;
            font-size: 15px;
            font-weight: 350;
            color: #000000;
          }
          -webkit-transition: all 0.2s linear;
          transition: all 0.2s linear;
        }
        div:hover {
          z-index: 2;
          -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
          -webkit-transform: translate3d(0, -2px, 0);
          transform: translate3d(0, -2px, 0);
          border: 2px solid #1b79ff;
        }
      }
    }
  }
  .footer{
    font-size: 11px !important;
  }
}
@media (max-width: 350px) {
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  height: 30px;
  background: #f6f6f6;
  font-size: 13px;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  color: #000000;
  line-height: 30px;
  span {
    cursor: pointer;
  }
}
</style>