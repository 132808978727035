<template>
  <router-view />
</template>
<script setup>
import { ref, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";

onMounted(() => {

});


</script>
<style>
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Arial", sans-serif;
}
</style>

